import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Notice from "../components/notice"
import Navbar from "../components/navbar"
import CallToAction from "../components/callToAction"
import Footer from "../components/footer"
import BlogFeatured from "../components/BlogFeatured"
import BlogHeader from "../components/BlogHeader"
import BlogMostRecent from "../components/BlogMostRecent"
import Seo from "../components/seo"

const Blog = ({ data }) => {
  const [tags, setTags] = useState([])
  const featuredPosts = data.featured.edges[0]?.node
  const title = featuredPosts?.articleTitle
  const metaDescription = featuredPosts?.synopsis
  const imageUrl = featuredPosts?.articleBannerImage?.file?.url
  const url = typeof window !== "undefined" ? window.location.href : ""

  const NoticeData = data?.notice?.edges?.[0]?.node
  const NavbarData = data?.navbar?.edges?.[1]?.node
  const CalltoActionData = data?.callToAction?.edges?.[0]?.node
  const footerData = data?.footerColumn?.edges?.[1]?.node

  const blogData = data.posts.edges
  const popularPosts = []
  const recentPosts = []
  blogData.forEach(({ node: blog }) => {
    if (blog.popular === true) popularPosts.push(blog)
    else recentPosts.push(blog)
  })

  useEffect(() => {
    const theTags = []
    blogData.forEach(({ node: blog }) => {
      !theTags.includes(blog.tags[0]) && theTags.push(blog.tags[0])
    })
    setTags(theTags.slice(0, 6))
  }, [blogData])

  return (
    <>
      <Seo
        title={title}
        metaDescription={metaDescription}
        imageUrl={imageUrl}
        url={url}
      />
      {NoticeData && <Notice NoticeData={NoticeData} />}
      {NavbarData && <Navbar NavbarData={NavbarData} />}

      {/* feature */}
      {featuredPosts && (
        <BlogFeatured featuredPosts={featuredPosts} backlink={false} />
      )}

      {/* tag and search */}
      <BlogHeader tags = {tags} tagName=""/>
      {/* popular   */}
      <BlogMostRecent 
        data = {popularPosts} 
        renderPagination = {false} 
        limit= {3}
        tagName = "Popular Articles"
      />
      {/* recent */}
      <BlogMostRecent 
        data = {recentPosts} 
        renderPagination = {true} 
        limit= {6}
        tagName = "Recent"
      />
      {CalltoActionData && <CallToAction CalltoActionData={CalltoActionData} />}
      <Footer variant="dark" footerData={footerData?.footerColumns} atlasOneLogo={footerData?.atlasOneLogo} />
    </>
  )
}
export default Blog

export const query = graphql`
  query BlogsQuery {
    posts: allContentfulArticle(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          articleBannerImage {
            file {
              url
            }
          }
          articleTitle
          tags
          featured
          popular
          synopsis
          createdAt
        }
      }
    }
    featured: allContentfulArticle(filter: { featured: { eq: true } }) {
      edges {
        node {
          articleBannerImage {
            file {
              url
            }
          }
          articleTitle
          tags
          featured
          popular
          synopsis
          updatedAt
        }
      }
    }
    notice: allContentfulNotice {
      edges {
        node {
          elementType
          theme
          noticeTitle {
            raw
          }
          tagline
          actionUrl
          noticeActionText
        }
      }
    }

    navbar: allContentfulHeader {
      edges {
        node {
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
          headerButtons {
            ... on ContentfulPrimaryButton {
              elementType
              id
              theme
              title
              url
            }
            ... on ContentfulSecondaryButton {
              elementType
              id
              theme
              title
              url
            }
          }
          headerMenuItems {
            id
            title
            headerMenuDropdown {
              items {
                description
                icon {
                  file {
                    url
                  }
                  title
                }
                title
                url
                page {
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }

    callToAction: allContentfulCallToAction {
      edges {
        node {
          elementType
          theme
          title
          desc:description
          buttons {
            ... on ContentfulSecondaryButton {
              elementType
              theme
              title
              url
            }
            ... on ContentfulPrimaryButton {
              elementType
              theme
              title
              url
            }
          }
        }
      }
    }

    footerColumn: allContentfulFooter {
      edges {
        node {
          elementType
          footerColumns {
            columnTitle
            links {
              title
              description
              icon {
                file {
                  url
                }
              }
              url
              page {
                slug
                title
              }
            }
          }
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          slug
          metaTitle
          metaDescription
          ogImage {
            file {
              url
            }
          }
          sections {
            ... on ContentfulNotice {
              elementType
              theme
              noticeTitle {
                raw
              }
              tagline
              actionUrl
              noticeActionText
            }
            ... on ContentfulHeader {
              headerMenuItems {
                title
                headerMenuDropdown {
                  items {
                    description
                    icon {
                      file {
                        url
                      }
                      title
                    }
                    title
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              headerButtons {
                ... on ContentfulPrimaryButton {
                  elementType
                  id
                  theme
                  title
                  url
                }
                ... on ContentfulSecondaryButton {
                  elementType
                  id
                  theme
                  title
                  url
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
            ... on ContentfulCallToAction {
              elementType
              theme
              title
              buttons {
                ... on ContentfulSecondaryButton {
                  elementType
                  theme
                  title
                  url
                }
                ... on ContentfulPrimaryButton {
                  elementType
                  theme
                  title
                  url
                }
              }
            }
            ... on ContentfulFooter {
              elementType
              ... on Node {
                ... on ContentfulFooterColumn {
                  columnTitle
                  links {
                    title
                    description
                    icon {
                      file {
                        url
                      }
                    }
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }

          }
        }
      }
    }
  }
`

